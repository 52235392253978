import React from "react"
// import PropTypes from 'prop-types';

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import "./white-paper-form.module.scss"

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

export default class WhitePaperForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      submitted: false,
      submitting: false,
      formData: {},
    }
  }

  handleChange = e => {
    const name = e.target.name
    const value = e.target.value
    this.setState(oldState => {
      return {
        ...oldState,
        formData: {
          ...oldState.formData,
          [name]: value,
        },
      }
    })
  }

  handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    this.setState({ submitting: true })

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state.formData,
      }),
    })
      .then(() => {
        this.setState({ submitted: true })
      })
      .catch(error => alert(error))
      .finally(() => {
        this.setState({ submitting: false })
      })
  }

  render() {
    const { submitting, submitted } = this.state

    return (
      <div styleName="wrapper">
        <Container styleName="customContainer">
          <div className="text-center" styleName="text-section">
            <h4 className="h2">Request white paper</h4>
            <p>
              The SHAPE white paper details the cutting-edge models, evidence
              base and business intelligence that makes SHAPE the ultimate
              people performance appraisal system.
            </p>
            <p>
              If you wish to access the SHAPE white paper, please provide your
              details below.
            </p>
          </div>
          <div styleName="formCard">
            <form
              name="white-paper"
              method="post"
              id="white-paper-form"
              action="/thanks/"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              onSubmit={this.handleSubmit}
              disabled={submitted}
            >
              <Row>
                <Col sm={12}>
                  <div styleName="input-parent">
                    <label>Your full name *</label>

                    <input
                      required
                      type="text"
                      name="name"
                      placeholder="Full name"
                      id="white-paper-fullname"
                      disabled={submitted}
                      className="form-control"
                      onChange={this.handleChange}
                    />
                  </div>
                  <div styleName="input-parent">
                    <label>Your email address *</label>

                    <input
                      required
                      type="text"
                      name="email"
                      id="white-paper-email"
                      placeholder="Email address"
                      disabled={submitted}
                      className="form-control"
                      onChange={this.handleChange}
                    />
                  </div>
                  <div styleName="input-parent">
                    <label>Your company name *</label>

                    <input
                      required
                      type="text"
                      name="company"
                      id="white-paper-company"
                      placeholder="Company name"
                      disabled={submitted}
                      className="form-control"
                      onChange={this.handleChange}
                    />
                  </div>
                  <div styleName="input-parent">
                    <label>Additional comments</label>

                    <textarea
                      name="comments"
                      placeholder="Additional comments"
                      id="white-paper-comments"
                      className="form-control"
                      onChange={this.handleChange}
                      disabled={submitted}
                      rows={6}
                    />
                  </div>
                </Col>
              </Row>

              <div className="text-center">
                {submitted && (
                  <div styleName="submittedSuccess">
                    Thank you. We’ll be in touch very shortly.
                  </div>
                )}
                <button
                  id="white-paper-form-button"
                  type="submit"
                  disabled={submitting || submitted}
                >
                  {(submitting && "Submitting...") ||
                    (submitted && "Submitted") ||
                    "Submit"}
                </button>
              </div>
            </form>
          </div>
        </Container>
      </div>
    )
  }
}
